import cx from 'classnames';
import type { ElementType, ComponentPropsWithoutRef } from 'react';

import { useControl } from './ControlProvider';

export type LabelProps<As extends ElementType> = ComponentPropsWithoutRef<As> & {
  labelClassName?: string;
};

export function Label<As extends ElementType>({
  as = 'label',
  className,
  children,
  labelClassName,
  ...rest
}: LabelProps<As>) {
  const AsComponent = as;
  const control = useControl();
  return (
    <AsComponent
      className={cx('flex items-center justify-between pb-1 cursor-pointer text-s-semibold', className)}
      {...rest}
      {...control.labelProps}
    >
      <span className={cx('text-s-semibold', labelClassName)}>{children}</span>
    </AsComponent>
  );
}
